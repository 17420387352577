:root {
    --fc-button-text-color: #FFFFFF;
    --fc-button-bg-color: #E48642;
    --fc-button-border-color: #E48642;
    --fc-button-hover-bg-color: #e47220;
    --fc-button-hover-border-color: #e47220;
    --fc-button-active-bg-color: #e47220;
    --fc-button-active-border-color: #e47220;
    --fc-border-color: #D0D5DD;
}

.fc .fc-toolbar-title {
    font-size: 1.5rem;
    margin: 0px;
    line-height: 2rem;
    color: #667085;
}