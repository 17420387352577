/* Success Toaster  */
.Toastify__toast--success {
  background-color: #78BD4F !important;
  color: #ffffff !important;
}

.Toastify__toast--success .Toastify__progress-bar--success {
  background-color: #4F7F32 !important;
}

.Toastify__toast--success .Toastify__close-button svg {
  fill: #ffffff;
}

.Toastify__toast--success .Toastify__close-button {
  color: #ffffff;
  opacity: 0.7;
}

/* Error Toaster  */
.Toastify__toast--error {
  background-color: #E48642 !important;
  color: #ffffff !important;
}

.Toastify__toast--error .Toastify__close-button svg {
  fill: #ffffff;
}

.Toastify__toast--error .Toastify__close-button {
  color: #ffffff;
  opacity: 0.7;
}

.Toastify__toast--error .Toastify__progress-bar--error {
  background-color: #A3542A !important;
}

/* Warning Toaster  */
.Toastify__toast-container--top-right:has(div.categoryToast) {
  width: 450px;
}

.Toastify__toast-container--top-right:has(div.refreshToast) {
  width: 400px;
}

.refreshToast {
  background-color: #E48642;
}

.refreshToast .Toastify__toast-body {
  align-items: start;
}

.refreshToast .Toastify__close-button {
  color: #fff !important;
  opacity: 0.8 !important;
}

.refreshToast .Toastify__close-button>svg {
  height: 20px;
  width: 20px;
}