.textInput {
 height: 3.6rem;
 width: 100%;
 border-radius: 10px;
 position: relative;
 background: #fff;

}


.textInput_label {
 position: absolute;
 top: 50%;
 transform: translateY(-50%);
 left: 1rem;
 right: 2rem;
 font-size: 18px;
 color: #667085;
 background-color: white;
 transition: all 0.3s ease;
 line-height: 1.1;


}

.textInput_label_focus {
 top: 0;
 font-size: 14px;
 left: 0.5rem;
 padding: 0 4px;
 color: #667085;
 font-weight: 500;
 right: unset;
}

.textInput_input {
 height: 100%;
 width: 100%;
 border: 1px solid #D0D5DD;
 border-radius: inherit;
 outline: none;
 font-size: 18px;
 color: #232323CC;
 padding-left: 1rem;
 padding-right: 1rem;
 background: #ffffff;

}

.textInput_input:focus {
 border-width: 1px !important;
 border-color: #667085 !important;
 box-shadow: none !important;
}

.textInput_input::placeholder {
 font-size: 18px;
}

.textInput_error {
 font-size: 1rem;
}

.textInput input:not(:placeholder-shown)+label {
 top: 0;
 font-size: 14px;
 left: 0.5rem;
 padding: 0 4px;
 right: unset;
}

.textArea {
 height: auto !important;
}

.textArea .textInput_label {
 top: 28px;
}

.textArea .textInput_label_focus {
 top: 0;
}


.phone_textInput input {
 height: 3.6rem;
 width: 100%;
 border-radius: 10px;
 position: relative;
 border: 1px solid #D0D5DD;
 outline: none;
 font-size: 18px;
 color: #232323CC;

}


.phone_textInput input:focus {
 border-width: 1px !important;
 border-color: #667085 !important;
 box-shadow: none !important;
}

.phone_textInput input::placeholder {
 font-size: 18px;
}

.phone_textInputError input {
 border-color: #fb7185;
}

.phone_textInputDash input {
 height: 3rem;
 width: 100%;
 border-radius: 5px;
 position: relative;
 border: 1px solid #D0D5DD;
 outline: none;
 font-size: 18px;
 color: #475569;

}


.phone_textInputDash input:focus {
 border-width: 1px !important;
 border-color: #CBD5E1 !important;
 box-shadow: none !important;
}


.phone_textInputDashError input {
 border-color: #fb7185 !important;
}